import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Note = makeShortcode("Note");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Migrating from `}<inlineCode parentName="h2">{`sx`}</inlineCode>{` props (Primer React)`}</h2>
    <p>{`:sparkles: A `}<a parentName="p" {...{
        "href": "https://marketplace.visualstudio.com/items?itemName=ian-sanders.sx-to-css"
      }}>{`VSCode extension`}</a>{` is available to assist with migrating React code to CSS Modules. The extension automatically handles converting JavaScript syntax to CSS, replacing Primer theme keywords with CSS variables, creating a module file, and more. `}</p>
    <h3>{`Spacing`}</h3>
    <h4>{`Stacks`}</h4>
    <p><a parentName="p" {...{
        "href": "https://primer.style/foundations/primitives/size#stack"
      }}>{`Stacks`}</a>{` are boxes containing flow content in a vertical or horizontal layout. These are usually `}<inlineCode parentName="p">{`display: flex`}</inlineCode>{` or `}<inlineCode parentName="p">{`grid`}</inlineCode>{` or `}<inlineCode parentName="p">{`block`}</inlineCode>{`.`}</p>
    <h5>{`Stack padding`}</h5>
    <p>{`Use these values for the internal `}<inlineCode parentName="p">{`padding`}</inlineCode>{` (including `}<inlineCode parentName="p">{`p`}</inlineCode>{`, `}<inlineCode parentName="p">{`px`}</inlineCode>{`, `}<inlineCode parentName="p">{`py`}</inlineCode>{`, `}<inlineCode parentName="p">{`pr`}</inlineCode>{`, etc shorthands) around the edge of the stack.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="th">{`sx`}</inlineCode>{` keyword`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`resolved value`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`primitive`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`0`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`0`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`1`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`4px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--base-size-4`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`2`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`8px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--base-size-8`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`3`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--base-size-16`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`4`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`24px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--base-size-24`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`5`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`32px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--base-size-32`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`6`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`40px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--base-size-40`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`7`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`48px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--base-size-48`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`8`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`64px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--base-size-64`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`9`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`80px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--base-size-80`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`10`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`96px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--base-size-96`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`11`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`112px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--base-size-112`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`12`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`128px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--base-size-128`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <h5>{`Stack margin`}</h5>
    <Note variant="warning" mdxType="Note">
 Prefer not using margin since it leaks space outside of components. Try to convert margins to container padding and/or flex layout gap.
    </Note>
    <p>{`See padding values above; there are no `}<inlineCode parentName="p">{`margin`}</inlineCode>{` primitives.`}</p>
    <h5>{`Stack content spacing`}</h5>
    <p>{`This is typically the CSS `}<inlineCode parentName="p">{`gap`}</inlineCode>{` in `}<inlineCode parentName="p">{`flex`}</inlineCode>{` (with `}<inlineCode parentName="p">{`flex-direction: column`}</inlineCode>{`) or `}<inlineCode parentName="p">{`grid`}</inlineCode>{` layouts. Could also be the `}<inlineCode parentName="p">{`margin`}</inlineCode>{` between items that have not been updated to flexbox/grid.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="th">{`sx`}</inlineCode>{` keyword`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`resolved value`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`primitive`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`0`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`0`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`1`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`4px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--base-size-4`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`2`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`8px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--stack-gap-condensed`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`3`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--stack-gap-normal`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`4`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`24px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--stack-gap-spacious`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`5`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`32px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--base-size-32`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`6`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`40px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--base-size-40`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`7`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`48px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--base-size-48`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`8`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`64px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--base-size-64`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`9`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`80px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--base-size-80`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`10`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`96px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--base-size-96`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`11`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`112px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--base-size-112`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`12`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`128px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--base-size-128`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <h4>{`Control stacks`}</h4>
    <p><a parentName="p" {...{
        "href": "https://primer.style/foundations/primitives/size#controls"
      }}>{`Control stacks`}</a>{` are horizontal (inline) rows containing inline interactive items next to each other. For example, a set of buttons or labels. These are usually `}<inlineCode parentName="p">{`display: flex`}</inlineCode>{` or `}<inlineCode parentName="p">{`inline-flex`}</inlineCode>{` with `}<inlineCode parentName="p">{`flex-direction: row`}</inlineCode>{`.`}</p>
    <Note variant="info" mdxType="Note">
 The size of the control stack depends on the size of the controls inside of it. For example, a stack containing a 'small' icon button is a 'small' stack.
    </Note>
    <h5>{`Control stack padding / margin`}</h5>
    <p>{`Use the same padding and margin values as for a vertical Stack.`}</p>
    <h5>{`Control stack content spacing`}</h5>
    <p>{`The `}<inlineCode parentName="p">{`gap`}</inlineCode>{` property in `}<inlineCode parentName="p">{`flex`}</inlineCode>{` layouts, or item `}<inlineCode parentName="p">{`margin`}</inlineCode>{` in older layouts.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="th">{`sx`}</inlineCode>{` keyword`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`resolved value`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`primitive`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`0`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`0`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`1`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`4px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--base-size-4`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`2`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`8px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--controlStack-small-gap-condensed`}</inlineCode>{` `}<br />{` `}<inlineCode parentName="td">{`--controlStack-medium-gap-condensed`}</inlineCode>{` `}<br />{` `}<inlineCode parentName="td">{`--controlStack-large-gap-condensed`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`3`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--controlStack-small-gap-spacious`}</inlineCode>{` `}<br />{` `}<inlineCode parentName="td">{`--controlStack-medium-gap-spacious`}</inlineCode>{` `}<br />{` `}<inlineCode parentName="td">{`--controlStack-large-gap-spacious`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`4`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`24px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--base-size-24`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`5`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`32px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--base-size-32`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`6`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`40px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--base-size-40`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`7`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`48px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--base-size-48`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`8`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`64px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--base-size-64`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`9`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`80px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--base-size-80`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`10`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`96px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--base-size-96`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`11`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`112px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--base-size-112`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`12`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`128px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--base-size-128`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <h4>{`Controls`}</h4>
    <p><a parentName="p" {...{
        "href": "https://primer.style/foundations/primitives/size#controls"
      }}>{`Controls`}</a>{` are `}<inlineCode parentName="p">{`Button`}</inlineCode>{`s, `}<inlineCode parentName="p">{`Label`}</inlineCode>{`s, etc -- small building block elements.`}</p>
    <p>{`These values must be resolved on a case-by-case basis because they are different for each size of control, each density level, and for the top/bottom vs the sides. See `}<a parentName="p" {...{
        "href": "https://primer.style/foundations/primitives/size#controls"
      }}>{`the primitives list`}</a>{` to find a good value or just use `}<inlineCode parentName="p">{`--base-size`}</inlineCode>{` values for now.`}</p>
    <h3>{`Colors`}</h3>
    <h4>{`Canvas`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="th">{`sx`}</inlineCode>{` keyword`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`primitive`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`canvas.default`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--bgColor-default`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`canvas.overlay`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--overlay-bgColor`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`canvas.inset`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--bgColor-inset`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`canvas.subtle`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--bgColor-muted`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <h4>{`Foreground`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="th">{`sx`}</inlineCode>{` keyword`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`primitive`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`fg.default`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--fgColor-default`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`fg.muted`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--fgColor-muted`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`fg.subtle`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`eliminated, use `}<inlineCode parentName="td">{`--fgColor-muted`}</inlineCode>{` instead`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`fg.onEmphasis`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--fgColor-onEmphasis`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <h4>{`Border`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="th">{`sx`}</inlineCode>{` keyword`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`primitive`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`border.default`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--borderColor-default`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`border.muted`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--borderColor-muted`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`border.subtle`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`eliminated, use `}<inlineCode parentName="td">{`--borderColor-muted`}</inlineCode>{` instead`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`Accent`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="th">{`sx`}</inlineCode>{` keyword`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`primitive`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`accent.fg`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--fgColor-accent`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`accent.emphasis`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--bgColor-accent-emphasis`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`accent.muted`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--borderColor-accent-muted`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`accent.subtle`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--bgColor-accent-muted`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <h4>{`Success, Attention, Severe, Danger, Open, Closed, Done, Sponsors`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="th">{`sx`}</inlineCode>{` keyword`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`primitive`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`{name}.fg`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--fgColor-{name}`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`{name}.emphasis`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--bgColor-{name}-emphasis`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`{name}.muted`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--borderColor-{name}-muted`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`{name}.subtle`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--bgColor-{name}-muted`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <h3>{`Fonts`}</h3>
    <h4>{`Font size`}</h4>
    <p>{`Try to use the one that applies to the type of text you are styling:`}</p>
    <h5>{`Titles`}</h5>
    <p><inlineCode parentName="p">{`Heading`}</inlineCode>{` or similar components.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="th">{`sx`}</inlineCode>{` keyword`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`resolved value`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`primitive`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`0`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`12px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--base-size-12`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`1`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`14px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--base-size-14`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`2`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--text-title-size-small`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`3`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`20px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--text-title-size-medium`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`4`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`24px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--base-size-24`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`5`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`32px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--text-title-size-large`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`6`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`40px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--base-size-40`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`7`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`48px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--base-size-48`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`8`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`56px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--base-size-56`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <h5>{`Body text`}</h5>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="th">{`sx`}</inlineCode>{` keyword`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`resolved value`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`primitive`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`0`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`12px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--text-body-size-small`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`1`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`14px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--text-body-size-medium`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`2`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--text-body-size-large`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`3`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`20px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--base-size-20`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`4`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`24px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--base-size-24`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`5`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`32px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--base-size-32`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`6`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`40px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--base-size-40`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`7`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`48px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--base-size-48`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`8`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`56px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--base-size-56`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <h4>{`Font family`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="th">{`sx`}</inlineCode>{` keyword`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`primitive`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`normal`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--fontStack-system`}</inlineCode>{` or explicitly `}<inlineCode parentName="td">{`--fontStack-sansSerif`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`mono`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--fontStack-monospace`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <h3>{`Borders`}</h3>
    <h4>{`Border color`}</h4>
    <p>{`See: `}<a parentName="p" {...{
        "href": "#border"
      }}>{`Border colors`}</a></p>
    <h4>{`Border width`}</h4>
    <p>{`Prefer these primitives instead of previously used numbers or plain pixel values:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="th">{`sx`}</inlineCode>{` keyword`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`resolved value`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`primitive`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`0`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`0`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`1`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--borderWidth-thin`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--borderWidth-thick`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`3px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--borderWidth-thicker`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <h4>{`Border radius`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="th">{`sx`}</inlineCode>{` keyword`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`resolved value`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`primitive`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`0`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`0`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`1`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`3px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--borderRadius-small`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`2`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`6px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--borderRadius-medium`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`12px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--borderRadius-large`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`3`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`a lot`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--borderRadius-full`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <h3>{`Sizes and Breakpoints`}</h3>
    <p>{`These are typically used for breakpoints, heights and widths.`}</p>
    <Note variant="info" mdxType="Note">
 For overlays (like dialogs), prefer the [new overlay primitives](https://primer.style/foundations/primitives/size#overlay). These sizes are not a 1:1 mapping with the old keywords.
    </Note>
    <Note variant="info" mdxType="Note">
  CSS variables cannot be used in '@media' or '@container' query expressions. The plain pixel value must be used instead. Stay tuned for future media query support.
    </Note>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="th">{`sx`}</inlineCode>{` breakpoint keyword`}</th>
          <th parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="th">{`sx`}</inlineCode>{` size keyword`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`resolved value`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`primitive`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`320px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--breakpoint-xsmall`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`0`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`size.small`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`544px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--breakpoint-small`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`1`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`size.medium`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`768px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--breakpoint-medium`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`2`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`size.large`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1012px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--breakpoint-large`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`3`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`size.xlarge`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1280px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--breakpoint-xlarge`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1400px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--breakpoint-xxlarge`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <h2>{`Migrating from SCSS Variables`}</h2>
    <p>{`Most legacy SCSS variables from Primer CSS have new CSS variable replacements.`}</p>
    <p>{`For values that have no replacement listed, use the raw value or find a replacement that is close in value.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Legacy `}<inlineCode parentName="th">{`scss`}</inlineCode>{` variable`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`New `}<inlineCode parentName="th">{`css`}</inlineCode>{` variable`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`$h00-size-mobile`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--text-display-size`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`$h0-size-mobile`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--text-title-size-large`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`$h1-size-mobile`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`26px `}<inlineCode parentName="td">{`deprecated`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`$h2-size-mobile`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`22px `}<inlineCode parentName="td">{`deprecated`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`$h3-size-mobile`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`18px `}<inlineCode parentName="td">{`deprecated`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`$h00-size`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--base-size-48`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`$h0-size`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--text-display-size`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`$h1-size`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--text-title-size-large`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`$h2-size`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--base-size-24`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`$h3-size`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--text-title-size-medium`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`$h4-size`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--text-title-size-small`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`$h5-size`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--text-body-size-medium`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`$h6-size`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--text-body-size-small`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`$font-size-small`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--text-body-size-small`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`$font-weight-bold`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--base-text-weight-semibold`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`$font-weight-semibold`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--base-text-weight-medium`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`$font-weight-normal`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--base-text-weight-normal`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`$font-weight-light`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--base-text-weight-light`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`$lh-condensed-ultra`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`$lh-condensed`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1.25`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`$lh-default`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1.5`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`$body-font`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--fontStack-system`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`$mono-font`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--fontStack-monospace`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`$body-font-size`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--text-body-size-medium`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`$body-line-height`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--text-body-lineHeight-medium`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`$spacer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--base-size-8`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`$spacer-0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0 `}<inlineCode parentName="td">{`deprecated`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`$spacer-1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--base-size-4`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`$spacer-2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--base-size-8`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`$spacer-3`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--base-size-16`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`$spacer-4`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--base-size-24`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`$spacer-5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--base-size-32`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`$spacer-6`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--base-size-40`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`$spacer-7`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--base-size-48`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`$spacer-8`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--base-size-64`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`$spacer-9`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--base-size-80`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`$spacer-10`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--base-size-96`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`$spacer-11`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--base-size-112`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`$spacer-12`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--base-size-128`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`$em-spacer-1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.0625em `}<inlineCode parentName="td">{`deprecated`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`$em-spacer-2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.125em `}<inlineCode parentName="td">{`deprecated`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`$em-spacer-3`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.25em `}<inlineCode parentName="td">{`deprecated`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`$em-spacer-4`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.375em `}<inlineCode parentName="td">{`deprecated`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`$em-spacer-5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.5em `}<inlineCode parentName="td">{`deprecated`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`$em-spacer-6`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.75em `}<inlineCode parentName="td">{`deprecated`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`$size`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--base-size-16`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`$size-0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0 `}<inlineCode parentName="td">{`deprecated`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`$size-1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--base-size-16`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`$size-2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--base-size-20`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`$size-3`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--base-size-24`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`$size-4`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--base-size-28`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`$size-5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--base-size-32`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`$size-6`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--base-size-40`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`$size-7`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--base-size-48`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`$size-8`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--base-size-64`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`$container-width`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`980px `}<inlineCode parentName="td">{`deprecated`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`$grid-gutter`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`10px `}<inlineCode parentName="td">{`deprecated`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`$width-xs`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0 `}<inlineCode parentName="td">{`deprecated`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`$width-sm`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--breakpoint-small`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`$width-md`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--breakpoint-medium`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`$width-lg`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--breakpoint-large`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`$width-xl`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--breakpoint-xlarge`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`$container-sm`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--breakpoint-small`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`$container-md`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--breakpoint-medium`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`$container-lg`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--breakpoint-large`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`$container-xl`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--breakpoint-xlarge`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`$viewport-narrow`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--viewportRange-narrow`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`$viewport-regular`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--viewportRange-regular`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`$viewport-wide`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--viewportRange-wide`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`$border-width`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--borderWidth-thin`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`$border-style`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`solid `}<inlineCode parentName="td">{`deprecated`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`$border`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`solid var(--borderWidth-thin) var(--borderColor-default)`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`$border-rem`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`solid var(--borderWidth-thin) var(--borderColor-default)`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`$border-radius-1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--borderRadius-small`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`$border-radius-2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--borderRadius-medium`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`$border-radius-3`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`8px --borderRadius-medium\``}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`$border-radius`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--borderRadius-small`}</inlineCode></td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      